import { routeMap } from '/routeMap'
import { Icon } from '/features/buildingBlocks/Icon'

import albronPayoffLogo from '/images/logo/albron-payoff.raw.svg'

import styles from './Footer.css'

export function Footer({ layoutClassName = undefined }) {
  return (
    <footer className={cx(styles.component, layoutClassName)}>
      <Icon icon={albronPayoffLogo} layoutClassName={styles.logoLayout} />
      <a className={styles.link} href={routeMap.app.cookiePolicy({ language: 'nl' })} data-x='link-to-cookie-and-policy'>Cookies & privacy statement</a>
    </footer>
  )
}
